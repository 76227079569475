import { template as template_546041a005d442b582277c4629de36d8 } from "@ember/template-compiler";
export default template_546041a005d442b582277c4629de36d8(`
  <dl class="information-wrapper" ...attributes>
    {{yield}}
  </dl>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
