import { template as template_5909141f12984289af86b710f07eb64d } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_5909141f12984289af86b710f07eb64d(`
  {{! TODO: move this component to Pix UI Later }}
  <fieldset class="pix-fieldset" ...attributes>
    <legend class="pix-fieldset__label">
      {{#if @required}}
        <abbr title={{t "common.form.mandatory-fields-title"}} class="mandatory-mark" aria-hidden="true">*</abbr>
      {{/if}}
      {{yield to="title"}}
    </legend>

    {{yield to="content"}}
  </fieldset>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
