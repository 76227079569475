import { template as template_4eb0a19071e341aa9ab13348e7fe6f05 } from "@ember/template-compiler";
import PixIconButton from '@1024pix/pix-ui/components/pix-icon-button';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import DropdownContent from './content';
export default class IconTrigger extends Component {
    @tracked
    display = false;
    @action
    toggle(event) {
        event.stopPropagation();
        this.display = !this.display;
    }
    @action
    close() {
        this.display = false;
    }
    static{
        template_4eb0a19071e341aa9ab13348e7fe6f05(`
    <div class="dropdown" ...attributes>
      <PixIconButton
        @iconName={{@icon}}
        @ariaLabel={{@ariaLabel}}
        class={{@dropdownButtonClass}}
        @triggerAction={{this.toggle}}
        @size="small"
        @color="dark-grey"
      />
      <DropdownContent @display={{this.display}} @close={{this.close}} class="{{@dropdownContentClass}}">
        {{yield this.close}}
      </DropdownContent>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
