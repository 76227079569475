import { template as template_8f9b1eea88a04e1491a244b569bbcbd1 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_8f9b1eea88a04e1491a244b569bbcbd1(`
  <p class="screen-reader-only">{{t "charts.participants-by-mastery-percentage.loader"}}</p>
  <div class="participants-by-mastery-percentage--loader placeholder-box" aria-hidden="true"></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
