import { template as template_9335bb03e0684557a22dab0b0a19b232 } from "@ember/template-compiler";
import PixIndicatorCard from '@1024pix/pix-ui/components/pix-indicator-card';
import { t } from 'ember-intl';
export default template_9335bb03e0684557a22dab0b0a19b232(`
  <PixIndicatorCard
    @title={{t "cards.participants-count.title"}}
    @iconName="users"
    @color="blue"
    @info={{t "cards.participants-count.information"}}
    @loadingMessage={{t "cards.participants-count.loader"}}
    @isLoading={{@isLoading}}
    ...attributes
  >
    <:default>{{@value}}</:default>
  </PixIndicatorCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
