import { template as template_7b6a93f7eb2f4fa48b6bfa3eba29d045 } from "@ember/template-compiler";
export default template_7b6a93f7eb2f4fa48b6bfa3eba29d045(`
  <div class="action-bar">
    <p class="action-bar__informations">
      {{yield to="information"}}
    </p>
    <div class="action-bar__actions">
      {{yield to="actions"}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
