import { template as template_f8d39a09e1b84220ae4750925e9d6f14 } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
export default template_f8d39a09e1b84220ae4750925e9d6f14(`
  <div class="explanation-card" ...attributes>
    <span class="explanation-card__title">
      <PixIcon @name="help" @plainIcon={{true}} class="explanation-card__icon" />
      <span>{{yield to="title"}}</span>
    </span>
    <p class="explanation-card__message">
      {{yield to="message"}}
    </p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
