import { template as template_2ab5be1613ba49ff9d18f7f442e67c38 } from "@ember/template-compiler";
import Certification from './certification';
import LanguageAvailability from './language-availability';
import Scommunication from './sco-communication';
import Survey from './survey';
export default template_2ab5be1613ba49ff9d18f7f442e67c38(`
  <div class="top-banners">
    <Certification />
    <Scommunication />
    <LanguageAvailability />
    <Survey />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
