import { template as template_ef1143e7281a4b8a9d8c681d090de520 } from "@ember/template-compiler";
import PixIndicatorCard from '@1024pix/pix-ui/components/pix-indicator-card';
import { t } from 'ember-intl';
export default template_ef1143e7281a4b8a9d8c681d090de520(`
  <PixIndicatorCard
    @title={{if
      @shouldDisplayAssessmentLabels
      (t "cards.submitted-count.title")
      (t "cards.submitted-count.title-profiles")
    }}
    @iconName="inboxIn"
    @color="green"
    @info={{t "cards.submitted-count.information"}}
    @loadingMessage={{t "cards.submitted-count.loader"}}
    @isLoading={{@isLoading}}
    ...attributes
  >
    <:default>{{@value}}</:default>
  </PixIndicatorCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
