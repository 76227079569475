import { template as template_8f7e4cfa6c3d4a618de00320ee3d159a } from "@ember/template-compiler";
import onClickOutside from 'ember-click-outside/modifiers/on-click-outside';
export default template_8f7e4cfa6c3d4a618de00320ee3d159a(`
  {{#if @display}}
    <ul class="dropdown__content" ...attributes {{onClickOutside @close}}>
      {{yield}}
    </ul>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
