import { template as template_effae543c6f14830a2130decaef99f51 } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_effae543c6f14830a2130decaef99f51(`
  <div class="app-loader">
    {{! template-lint-disable no-redundant-role }}
    <img src="/images/interwind.gif" alt="" role="presentation" />
    <p class="app-loader__text">
      {{t "common.loading"}}
    </p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
