import { template as template_2f7ea76535bd4138beade60248665498 } from "@ember/template-compiler";
import PixIconButton from '@1024pix/pix-ui/components/pix-icon-button';
export default template_2f7ea76535bd4138beade60248665498(`
  <PixIconButton
    aria-label={{@ariaLabel}}
    @iconName="{{if @isOpen 'chevronTop' 'chevronBottom'}}"
    aria-expanded="{{@isOpen}}"
    @triggerAction={{@onClick}}
    @withBackground={{false}}
    @size="small"
    @color="dark-grey"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
