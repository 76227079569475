import { template as template_ed4ee2fe0a1a4922ba1036a16a25f693 } from "@ember/template-compiler";
export default template_ed4ee2fe0a1a4922ba1036a16a25f693(`
  <div class="form-field">
    <div class="form-field__element">
      {{yield}}
    </div>

    <div class="form-field__information">
      {{yield to="information"}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
