import { template as template_1c44af2331bb4d6ba0aef73f35491620 } from "@ember/template-compiler";
import CampaignBadgeAcquisitions from '../charts/campaign-badge-acquisitions';
import ParticipantsByMasteryPercentage from './participants-by-mastery-percentage';
import ParticipantsByStage from './participants-by-stage';
export default template_1c44af2331bb4d6ba0aef73f35491620(`
  <div class="stats">
    {{#if @campaign.hasStages}}
      <ParticipantsByStage @campaignId={{@campaign.id}} @onSelectStage={{@onSelectStage}} class="participants" />
    {{else}}
      <ParticipantsByMasteryPercentage @campaignId={{@campaign.id}} class="participants hide-on-mobile" />
    {{/if}}
    {{#if @campaign.hasBadges}}
      <CampaignBadgeAcquisitions @campaignId={{@campaign.id}} />
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
