import { template as template_6b0f9f30d8a2457eb2e147b7200d3c9a } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
import { t } from 'ember-intl';
export default template_6b0f9f30d8a2457eb2e147b7200d3c9a(`
  <PixBlock class="no-campaign-panel">
    <img src="{{this.rootURL}}/images/empty-state.svg" alt="" role="none" />

    <p class="no-campaign-panel__information-text hide-on-mobile">
      {{t "pages.campaigns-list.no-campaign"}}
    </p>
  </PixBlock>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
