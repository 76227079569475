import { template as template_988a1eddf8104f7f8733aebabf3a1b91 } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
export default template_988a1eddf8104f7f8733aebabf3a1b91(`
  {{! template-lint-disable require-input-label }}
  <div class="input search-input" ...attributes>
    <PixIcon @name="search" />
    <input
      id={{@inputName}}
      name={{@inputName}}
      placeholder={{@placeholder}}
      aria-label={{@label}}
      value={{@value}}
      oninput={{@onSearch}}
      class="search-input__invisible-field"
    />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
