import { template as template_123a08f0290740c0be899d69fd4c3c4f } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import { t } from 'ember-intl';
import ActionBar from '../ui/action-bar';
export default template_123a08f0290740c0be899d69fd4c3c4f(`
  <ActionBar>
    <:information>
      {{t "pages.sup-organization-participants.action-bar.information" count=@count}}
    </:information>
    <:actions>
      <PixButton @triggerAction={{@openDeletionModal}} type="button" @variant="error">
        {{t "pages.sup-organization-participants.action-bar.delete-button"}}
      </PixButton>
    </:actions>
  </ActionBar>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
