import { template as template_ec2db1e793274032a4e7f247878496df } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { gt } from 'ember-truth-helpers';
export default template_ec2db1e793274032a4e7f247878496df(`
  <div class="table__column--center">
    {{#if (gt @tutorials.length 0)}}
      {{t "pages.campaign-review.table.analysis.column.tutorial-count.value" count=@tutorials.length}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
