import { template as template_89f7055ad25140d9817b7afcc0845ce4 } from "@ember/template-compiler";
import OrganizationPlacesOrCreditInfo from './organization-places-or-credit-info';
import SchoolSessionManagement from './school-session-management';
import UserLoggedMenu from './user-logged-menu';
export default template_89f7055ad25140d9817b7afcc0845ce4(`
  <div class="topbar">
    <OrganizationPlacesOrCreditInfo @placesCount={{@placesCount}} />
    <SchoolSessionManagement />
    <UserLoggedMenu class="topbar__user-logged-menu" @onChangeOrganization={{@onChangeOrganization}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
