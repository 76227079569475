import { template as template_31c6aee228684e18b624de151937ce19 } from "@ember/template-compiler";
export default template_31c6aee228684e18b624de151937ce19(`
  <div class="information" ...attributes>
    <dt class="information__title">
      {{yield to="title"}}
    </dt>
    <dd class="information__content {{@contentClass}}">
      {{yield to="content"}}
    </dd>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
