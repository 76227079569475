import { template as template_85556801e0304071b7b06c0936b1708c } from "@ember/template-compiler";
import PixBlock from '@1024pix/pix-ui/components/pix-block';
export default template_85556801e0304071b7b06c0936b1708c(`
  <PixBlock class="empty-state">
    <img src="{{this.rootURL}}/images/empty-state-participants.svg" alt="" role="none" />
    <p class="participants-empty-state__text">{{@infoText}}</p>
    <p class="participants-empty-state__text">{{@actionText}}</p>
  </PixBlock>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
