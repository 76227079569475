import { template as template_6cae07bd46af4affa7a496719ce20ead } from "@ember/template-compiler";
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import PageTitle from '../ui/page-title';
export default class ScoHeaderActions extends Component {
    @service
    currentUser;
    static{
        template_6cae07bd46af4affa7a496719ce20ead(`
    <div class="organization-participant-list-page__header">
      <PageTitle>
        <:title>
          {{t "pages.sco-organization-participants.title" count=@participantCount}}
        </:title>
        <:tools>
          {{#if this.currentUser.isAdminInOrganization}}
            <PixButtonLink @route="authenticated.import-organization-participants" class="hide-on-mobile">
              {{t "components.organization-participants-header.import-button"}}
            </PixButtonLink>
          {{/if}}
        </:tools>
      </PageTitle>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
